// Here you can add other styles
.body {
  font-family: 'Roboto', sans-serif !important;
  line-height: 0.25 !important;
}

.app {
  position: relative;
}

.loader {
  position: absolute;
  margin: 0 auto;
  height: 100%;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
  z-index: 10000;
  width: 100%;
  background: #dedede;
  opacity: 0.8;
  padding-left: calc(50% + 10px);
}

.loader .logo-loader {
  width: 70px;
  margin-left: 10px;
}

.loader .loader-img {
  width: 90px;
  height: auto;
  margin-top: -15px;
}

.position-relative {
  position: relative;
}

.btn-success,
.btn-info,
.btn-warning,
.btn-delete {
  color: #fff !important;
}

.width-50-percent {
  width: 50%;
}

.pagination-bottom {
  margin-top: 10px;
}

.password-recover-text {
  margin-top: 10px;
  font-style: italic;
  color: #8c8c8c;
}

.user-recover {
  margin: 10px 0;
}

.card-body {
  padding-top: 30px;
}

.card-footer {
  padding: 15px;
}

.card-fieldset {
  padding: 20px;
  border: 1px solid #cad0d5;
  border-radius: 10px;
  background: #f8fcff !important;
}

.card-legend {
  width: auto;
  padding: 0 10px;
}

.margin-top-10 {
  margin-top: 10px;
}

.react-datepicker-wrapper input {
  border-radius: 5px;
  padding: 5px 15px;
  border: 1px solid #e6e8eb;
}

.input-80-inline {
  width: 80%;
  display: inline-block;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.btn.btn-default {
  border: 1px solid #c4c4c4;
}

.picture-scroll {
  width: 100%;
  height: 500px;
  overflow: scroll;
}

input,
select {
  color: #000 !important;
}

.loading-image {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.loading-image img {
  width: 100%;
  height: 100%;
}

.btn-groups .btn {
  margin-right: 10px;
  cursor: pointer;
}

.logo {
  width: 140px;
  height: 190px;
  margin-top: -50px;
  margin-bottom: 20px;
  display: inline-block;
  border: 2px solid;
}

.action-buttons button {
  display: inline;
  margin-left: 10px;
}

.action-buttons button:first-child {
  margin-left: 0;
}

.action-buttons button:last-child {
  margin-bottom: 0;
}

.input-group-prepend .fa {
  width: 40px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #e4e7ea;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #8189a3;
  color: #757575;
}

.input-group-prepend .input-group-text i {
  display: block;
}

.group-right {
  display: inline !important;
  float: right !important;
}

.language-icon-box {
  height: 35px;
  width: 40px;
}

.language-icon-box .input-group-text {
  padding-left: 9px;
  padding-top: 15px;
}

.loading-image {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: #f0f3f4;
}

.loading-image img {
  width: 600px !important;
  height: 340px !important;
  left: 28%;
  top: 15%;
  position: absolute;
}

.hidden {
  display: none !important;
}

.tables-checkbox {
  width: 25px;
}

.form-check-input {
  display: inline-block;
}

.table-responsive .table {
  border-left: 1px solid #c8ced3;
  border-right: 1px solid #c8ced3;
}

.table-responsive .table tr {
  border-bottom: 1px solid #c8ced3;
}

.sidebar .nav-link {
  border: 0;
  //background: #2a3b59;
  width: 100%;
  text-align: left;
  border-radius: 0;
  color: #fff !important;
  border-bottom: 1px solid #acb5bc7a !important;
  cursor: pointer;
}

.sidebar .nav-dropdown.open {
  background: #7b0c00 !important;
}

.sidebar .nav-link i {
  color: #fff !important;
}

.sidebar .nav-link.active {
  color: #fff;
  background: #873a3c;
}

.sidebar .nav-link:hover {
  background: #873a3c !important;
}

.sidebar .nav-link.active .nav-icon {
  color: #fff;
}

.sidebar .nav-dropdown-items .nav-item {
  padding: 0;
  list-style: none;
  margin-left: 15px;
}

.language-flag {
  height: 20px;
  margin-top: -10px;
}

.register-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.action-buttons-bottom-box {
  margin-bottom: 0;
}

.card-fieldset .form-group label {
  margin-top: 5px;
}

.card-body {
  flex: 1 1 auto;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.app-header {
  background-color: #7b0c00 !important;
  height: 70px;
}

.app-body {
  margin-top: 70px!important;
}

.table tbody th a {
  font-weight: 400;
  color: #010811 !important;
}

/*----------------------  Change style of layout  ---------------------*/
.navbar-nav .nav-link {
  color: #000000 !important;
}

.admin-left {
  display: block !important;
  float: left;
}

.admin-right {
  display: block !important;
  float: right;
}

.admin-margin-left {
  margin-left: 10px;
}

.admin-form-tittle {
  display: block !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.admin-footer-paging {
  margin-right: 20px;
  display: block;
  float: right;
}

.table th,
.table td {
  vertical-align: middle !important;
}

.badge {
  display: inline-block;
  padding: 0.3em 0.3em !important;
  font-size: 100% !important;
  margin-right: 5px;
}

.admin-bg-dark {
  background: #91c5d4;
}

.admin-border-bottom {
  border-bottom: 1px solide #e4e7ea;
}

.admin-margin-left {
  margin-left: 5px;
}

.action-buttons button:last-child {
  margin-bottom: 0;
}

.action-buttons button.btn-info {
  color: #fff !important;
}

.input-group-prepend .fa {
  width: 40px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #e4e7ea;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #f0f3f5;
  color: #757575;
}

.loading-image {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: #f0f3f4;
}

.loading-image img {
  width: 600px !important;
  height: 340px !important;
  left: 28%;
  top: 15%;
  position: absolute;
}

.hidden {
  display: none !important;
}

.action-inline-block {
  display: inline-block;
  margin-left: 5px;
}

.tooltip-inner {
  background-color: #efb103;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #efb103;
}

.react-datepicker-wrapper input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.card-header .input-group {
  display: inherit !important;
}

.card-header {
  padding: 0.5rem 1.25rem !important;
  text-align: left;
}

.search-box .card-header {
  display: none !important;
}

.search-box .card-footer {
  padding: 0px !important;
}

.search-box .card {
  background-color: #f0f3f5;
}

.search-box .col-md-6 {
  background: #f0f3f5;
}

.form-group.row span input {
  border-radius: 3px !important;
  border: 1px solid #e4e5e6 !important;
  padding: 5px 10px !important;
  width: 100% !important;
}

.search-box .card-fieldset {
  padding: 0px;
  border: 0px;
  border-radius: 0px;
  background: #f0f3f5 !important;
}

.form-control-static {
  text-align: left;
  border-bottom: 1px solid #ebf0f3;
  min-height: 20px !important;
}

.react-datepicker__time-container {
  width: 120px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 120px !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 130px !important;
}

@media screen and (max-width: 600px) {
  .form-group label {
    float: left !important;
  }

  ol.breadcrumb {
    display: none !important;
  }
}

.form-group label {
  font-weight: 600;
}

@media (min-width: 768px) {
  .col-md-special-2 {
    max-width: 12.5%;
  }

  .col-md-special-10 {
    max-width: 87.5%;
  }
}

.navbar-nav .btn-group {
  margin-left: 4px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-radius: 2px !important;
  color: #fff;
  border: 1px solid #ccc;
  background: none !important;
}

ol.breadcrumb {
  background: none !important;
  border: none;
  vertical-align: middle !important;
  top: 10px !important;
}

.dropdown-item:first-child {
  background-color: #e4e7ea;
}

.full-lines .row > div:first-child {
  width: 12.5%;
  max-width: unset;
  flex: unset;
}

.full-lines .row > div:last-child {
  width: 87.5%;
  max-width: unset;
  flex: unset;
}

.album-save-box > .row > div, .album-save-box > .row > div .full-lines {
  width: 100%!important;
}

.album-save-box .full-lines .row > div:first-child {
  width: 12.5%;
}

.album-save-box .full-lines .row > div:last-child {
  width: 87.5%;
}

.files {
  position: fixed;
  top: 10%;
  left: 18%;
  width: 80%;
  height: 80%;
  background: #fff;
  padding: 20px;
  z-index: 1;
  border: 1px solid #20a8d8;
}

.files .files-dropzone {
  border: 1px solid #FF5722;
  padding: 10px;
  height: 60%;
  width: 300px;
  position: absolute;
  right: 20px;
  top: 85px;
  padding-top: 15%;
}

.file-avatar {
  width: 140px;
  height: 100px;
  text-align: center;
  line-height: 20px;
  margin-top: 20px;
  margin-right: 15px;
  border: 1px solid #ccc;
  background: #fcfdff;
  display: inline-block;
  position: relative;
  float: left;
  cursor: pointer;
}

.file-avatar .file-name {
  position: absolute;
  top: 30px;
  font-size: 12px;
  color: #2a3b59;
}

.albums-medias-box {
  position: relative;
}

.header-medias {
  padding-bottom: 10px;
  border-bottom: 1px solid #274e75;
}

.close-medias {
  position: absolute;
  top: -10px;
  right: -10px;
  color: #fff;
  background: #53b2d2;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
}

.files-box {
  width: 67%;
}

/* Modal of vinsen */
.vs-box {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #20a8d8;
  padding: 10px;
  background: #fff;
  color: #274e75;
  width: 100%;
  z-index: 10021;
}

.confirm-modal .vs-box .col-md-3 {
  width: 100% !important;
  text-align: left !important;
  max-width: 100% !important;
  flex: none !important;
}

.confirm-modal .vs-box .col-md-9 {
  display: none !important;
}

.vs-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #101113;
  z-index: 10020;
  opacity: 0.7;
}

.vs-close {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid #6d0606;
  color: #6d0606;
  padding-left: 4px;
  line-height: 18px;
}

.vs-box-header {
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #20a8d8;
}

.confirm-modal {
  position: relative;
}
.confirm-modal .card-header {
  display: none;
}
.confirm-modal .card {
  margin-bottom: 0;
}
.confirm-modal label span {
  display: none;
}
.media-files .file-avatar.file-image img {
  height: 20px;
}
.media-files .file-avatar .file-name {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.files-dropzone {
  background-color: #1593b8;
    width: 140px;
    height: 100px;
    margin-top: 20px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    padding-top: 38px;
    text-align: center;
    cursor: pointer;
    float: left;
}

.font-library {
  font-size: 80px;
  color: burlywood;
  line-height: 100px;
  cursor: pointer;
}

.album-name {
  width: 200px;
  text-align: left;
}

.file-avatar.file-image img {
  width: 20px;
}

.title-header {
  color: #ffffff;
  display: contents !important;
}

.dashboard-search-box {
  width: 100%;
}

.profile-box {
  position: absolute;
  background: #64a4e3;
  color: #fff;
  top: 40px;
  right: 10px;
  border: 1px solid;
  width: 110px;
}

.profile-box .pb-item {
  border-bottom: 1px solid;
  padding: 10px;
}

.sidebar {
  background: #af1100 !important;
}